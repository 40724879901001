import './styles.css'

function SideBarLeftTitle(){
    return (
        <div class="sidebarLeftTitle">
            <h2 class="title">Explorer</h2>
        </div>
    )
}

export default SideBarLeftTitle