import './style.css'
import StatusBarFirstVisibleItem from 'components/StatusBarFirstVisibleItem'

function StatusBar() {
    return (
        <div class="statusBar">
            <StatusBarFirstVisibleItem></StatusBarFirstVisibleItem>
            
        </div>
    )
}

export default StatusBar